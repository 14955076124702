import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HomeScreen />} path={"/"} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
