import React from 'react';

const HomeScreen = () => {
  return (
    <div>
      Home
    </div>
  );
};

export default HomeScreen;
